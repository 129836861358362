module.exports = [{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Lokal","short_name":"Lokal","start_url":"/","background_color":"#F6DD8F","theme_color":"#F6DD8F","display":"minimal-ui","icon":"src/images/lokalcommissionstab.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"502dd76583f9d9d7b23b14120f0ec4f2"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
