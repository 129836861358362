/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
/*
const React = require("react")
const {
  StripeProvider,
  StripeContext,
} = require("./src/components/stripeutils")
const { Elements } = require("@stripe/react-stripe-js")

//Change to live Stripe Key in production.

exports.wrapRootElement = ({ element, props }) => {
  return (
    <StripeProvider>
      <StripeContext.Consumer>
        {stripePromise => {
          console.log("Stripe Promise in Wrapper is: " + JSON.stringify(stripePromise))
          return <Elements stripe={stripePromise}>{element}</Elements>
        }}
      </StripeContext.Consumer>
    </StripeProvider>
  )
}
*/
import "./src/styles/index.css"